<template>
  <div class="xiaofan">

    <div>

      <h3 style="text-align: center;">关于 Gray Model 模型的相关说明</h3>

      <div>
        <el-steps :active="4" align-center>
          <el-step title="模型说明" description="这是一段很长很长很长的描述性文字"></el-step>
          <el-step title="模型应用" description="这是一段很长很长很长的描述性文字"></el-step>
          <el-step title="模型调试" description="这是一段很长很长很长的描述性文字"></el-step>
          <el-step title="模型评估" description="这是一段很长很长很长的描述性文字"></el-step>
        </el-steps>
      </div>
    </div>


    <div style="margin-top:12px; ">
      <h3>一、模型说明</h3>
      <h4>1.1 灰色系统、白色系统和黑色系统
      </h4>
      <p class="p-character">白色系统（白箱系统）是指一个系统的内部特征是完全已知的，既系统信息是完全充分的。</p>
      <p class="p-character">黑色系统（黑箱系统）是一个系统的内部信息对外界来说是一无所知的，只能通过它与外界的联系来加以观测研究。</p>
      <p class="p-character">
        灰色系统介于白色和黑色之间，灰色系统内的一部分信息是已知的，另一部分信息是未知的，系统内各因素间有不确定的关系。一般地说，社会系统、经济系统、生态系统都是灰色系统。例如物价系统，导致物价上涨的因素很多，但已知的却不多，因此对物价这一灰色系统的预测可以用灰色预测方法。
      </p>

      <h4>1.2 灰色系统理论</h4>
      <p class="p-character">
        灰色系统理论认为对既含有已知信息又含有未知或非确定信息的系统进行预测，就是对在一定方位内变化的、与时间有关的灰色过程的预测。尽管过程中所显示的现象是随机的、杂乱无章的，但毕竟是有序的、有界的，因此这一数据集合具备潜在的规律，灰色预测就是利用这种规律建立灰色模型对灰色系统进行预测。
      </p>
      <h4>1.3 灰色预测</h4>
      <p class="p-character">
        灰色预测通过鉴别系统因素之间发展趋势的相异程度，即进行关联分析，并对原始数据进行生成处理来寻找系统变动的规律，生成有较强规律性的数据序列，然后建立相应的微分方程模型，从而预测事物未来发展趋势的状况。其用等时距观测到的反应预测对象特征的一系列数量值构造灰色预测模型，预测未来某一时刻的特征量，或达到某一特征量的时间。
      </p>
      <h4>1.4 表达式</h4>
      <p class="p-character">函数表达式为GM(n,x)模型,其含义是:用n阶微分方程对x个变量建立模型,常用的表达式为GM(1,1)——表示为针对一个变量，并且使用一阶微分方程来构建模型。</p>
      <h4>1.5 优缺点</h4>
      <p class="p-character">灰色系统预测模型的优点：无需大量数据样本，短期预测效果好，运算过程简单。</p>
      <p class="p-character">灰色系统预测模型的缺点：对非线性数据样本预测效果差。</p>
    </div>


    <div style="margin-top:12px; ">
      <h3>二、模型应用</h3>

      <h4>2.1 业务需求与数据指标</h4>
      <p class="p-character">
        随着烟草行业竞争加剧,越来越多的业内人士开始关注卷烟销量预测问题。卷烟销量主要分三种:工业销量、商业销量以及社会销量。工业销量由于其较强的计划性基本无需预测,社会销量因销售数据难以收集,导致历史数据稀少,
        所以不具备预测的基础,而商业销量数据随着烟草行业信息化逐渐完善已细分到各卷烟及各地市,并已有10年以上的历史记录。以下则是按照商业销量的维度进行。其相关的指标如下表格：</p>
      <el-table :data="tableData" height="250" border style="width: 100%; ">
        <el-table-column prop="month" label="月份">
        </el-table-column>
        <el-table-column prop="name" label="品牌名称">
        </el-table-column>
        <el-table-column prop="cost" label="成本">
        </el-table-column>
        <el-table-column prop="sales_no" label="销售数量">
        </el-table-column>
      </el-table>

      <h4>2.2 模型构建--GM(1,1) VS GM(2,1) VS GM(n,x)</h4>


      <el-row :gutter="12">
        <el-col :span="8">
          <el-card shadow="hover"
            style="font-size: 16px; text-align: center; font-weight: bold; color: #303133; margin:12px">
            GM(1,1)
            <el-divider></el-divider>
            <p style="font-size: 12px; text-align: center; font-weight: normal; color: #303133; margin:12px">
              GM(1,1)适合的场景： 对于一个变量,且序列与年份的函数图像接近指数函数或直线,是单调的变化过程。</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover"
            style="font-size: 16px; text-align: center; font-weight: bold; color: #303133; margin:12px">
            GM(2,1)
            <el-divider></el-divider>
            <p style="font-size: 12px; text-align: center; font-weight: normal; color: #303133; margin:12px">
              GM(2,1)适合的场景： 对于一个变量,且序列与年份的函数图像是非单调的摆动序列或饱和的S型序列。</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover"
            style="font-size: 16px; text-align: center; font-weight: bold; color: #303133; margin:12px">
            GM(n,x)
            <el-divider></el-divider>
            <p style="font-size: 12px; text-align: center; font-weight: normal; color: #303133; margin:12px">
              GM(n,x)适合的场景： 对于x个变量,需要按照数值的特征,针对性的判断确定n阶的微分与求导的算法,来实现模型的最优。</p>
          </el-card>
        </el-col>
      </el-row>

      <p class="p-character">GM(1,1)的运算与推导过程</p>
      <p class="p-character">已知元素序列: <math-jax class="p-character"
          latex="x^{(0)} = ( X^{(0)}(1),X^{(0)}(2),...,X^{(0)}(n))"></math-jax>
      </p>
      <p class="p-character">做一次累加生成(1-AGO)序列: </p>
      <math-jax class="p-character" latex="x^{(1)} = ( X^{(1)}(1),X^{(1)}(2),...,X^{(1)}(n))"></math-jax>
      <p class="p-character">其中: <math-jax class="p-character"
          latex="x^{(1)(k)} = ( \sum_{i=0}^n x^{(0)}(i),  k=1,2,3,...n"></math-jax>
      </p>
      <p class="p-character">令 <math-jax latex="Z^{(1)}"></math-jax> 为 <math-jax latex="X^{(1)}"></math-jax> 的紧邻均值生成序列：
      </p>

      <math-jax class="p-character" latex="Z^{(1)} = ( Z^{(1)}(1),Z^{(1)}(2),...,Z^{(1)}(n))"></math-jax>
      <p class="p-character">其中各元素的表示如下：</p>
      <math-jax class="p-character" latex="z^{(1)}(k) = 0.5x^{(1)}(k) + 0.5x^{(1)}(k-1)"></math-jax>
      <p class="p-character">转换成为基于 <math-jax latex=" x^{(0)}(k) + az^{(1)}(k) = b"></math-jax>
      </p>
      <p class="p-character">其中，a为发展系数，b为灰色作用量。 具体的采用最小二乘法进行相应的求解即可！</p>



      <h4>2.3 参考文献与说明</h4>
      <el-descriptions title="">
        <el-descriptions-item label="文档">Python GM 模型的 Package</el-descriptions-item>
        <el-descriptions-item label="地址"><a class="a-style"
            @click="linkURL('https://github.com/Kalvar/python-GreyTheory')">https://github.com/Kalvar/python-GreyTheory</a>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">Package</el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="文档">Python GM 模型的说明与参数配置 Package</el-descriptions-item>
        <el-descriptions-item label="地址"><a class="a-style"
            @click="linkURL('https://zhuanlan.zhihu.com/p/421156968')">https://zhuanlan.zhihu.com/p/421156968</a>

        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">案例</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="文档">Python GM 算法与公式的推导 Package</el-descriptions-item>
        <el-descriptions-item label="地址">
          <a class="a-style"
            @click="linkURL('https://blog.csdn.net/qq_39742013/article/details/82120561')">https://blog.csdn.net/qq_39742013/article/details/82120561</a>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">案例</el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="文档">Python GM 算法与公式的推导 Package</el-descriptions-item>
        <el-descriptions-item label="地址"><a class="a-style"
            @click="linkURL('http://html.rhhz.net/yykj/html/201812020.htm')">http://html.rhhz.net/yykj/html/201812020.htm</a>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">案例</el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="文档">Python GM(1,1) 算法与公式的推导过程</el-descriptions-item>
        <el-descriptions-item label="地址"><a class="a-style"
            @click="linkURL('https://www.cnblogs.com/wuzaipei/p/11925321.html')">https://www.cnblogs.com/wuzaipei/p/11925321.html</a>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">案例</el-tag>
        </el-descriptions-item>



      </el-descriptions>




    </div>





    <div style="margin-top:22px; ">
      <h3>三、模型调试</h3>
      <el-divider direction="vertical"></el-divider>
      <el-col :span="18">
        <el-input v-model="testdata" placeholder="请输入连续月份的数据，并以英文的逗号分开!"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getModeltest" style="margin-left:12px;">获取预测(预测下一个数值)</el-button>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" @click="isShow" style="margin-left:12px;">点击</el-button>
      </el-col>

      <br><br><br><br>
      <h3 style="text-align: center;">GM Predict result: {{ testresult }}</h3>
    </div>


    <div style="margin-top:22px; ">
      <h3>四、模型评估</h3>
    </div>





    <!-- <div v-show="ShowStatus"> -->
    <div v-show="ShowStatus" style="height:400px; width:1200px;margin: auto;" id="echarts1"></div>
    <!-- </div> -->



    <div style="padding-top: 20px">
      <!-- <input type="text" v-model="fomule" /> -->
      <math-jax :latex="fomule" :block="true" style="font-size: 55px; "></math-jax>
    </div>





  </div>

</template>




<script>

import * as echarts from 'echarts'
import baseUrl from '../../../util/config'
// let baseUrl = 'http://localhost:8081'

export default {

  data() {
    return {
      tableData: [],
      testdata: '',
      testresult: '',
      fomule: "\\sum_{i=0}^n i^2 = \\frac{(n^2+n)(2n+1)(2n_t+1)}{6}",
      ShowStatus: 0,
      chart4x:[],
      chart4y:[],
      option: {
        title: {
          text: '关于 Gray Model 预测数据的图表'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []    //  坐标轴X的数值系列
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} (单位)'
          }
        },
        series: [
          {
            name: 'Gray Model Test',
            type: 'line',
            data: [],    //  坐标轴Y的数值系列
            markPoint: {
              data: [{yAxis:'', x:''}]
            },
            markLine: {
              data: [{ type: 'average', name: 'Avg' }]
            }
          }
        ]
      }

    };
  },
  mounted() {
    this.getTableData(),
    this.ecahrts1()
  },

  methods: {

    //数据指标
    getTableData() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/gray_model/model_data',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          // console.log(res.data)
          this.tableData = res.data
        },
        err => { }
      )
    },

    getModeltest() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/gray_model/model_service?key=' + this.testdata,
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data)
          this.testresult = res.data
          console.log(this.testresult)
        },
        err => {
          this.$message.error('请注意输入的数据格式！')
        }
      )
    },

    linkURL(url) {
      window.open(url, '_blank') // 新窗口打开外链接
    },


    ecahrts1() {
      let echarts1 = document.getElementById('echarts1')
      let myecharts1 = echarts.init(echarts1)
      
      myecharts1.setOption(this.option, true)


    },

    isShow() {
      if (this.testresult == '') {
        // this.$alert('需要先进行预测,再进行数据验证&可视化！', 'Note:')
        this.$message.error('需要先进行预测,再进行数据验证&可视化！');
      }
      else {
        this.ShowStatus = !this.ShowStatus //取反

        this.chart4x = Array.from({ length: this.testdata.split(',').length + 1 }, (_, index) => index + 1)
        this.chart4y = this.testdata.split(',').map(Number)
        this.chart4y.push(this.testresult)


        this.option.xAxis.data = this.chart4x
        // JS 数组的获取方式[0]即可实现数据的赋值.
        this.option.series[0].data = this.chart4y
        // Echarts数据变化之后的重新渲染.

        this.option.series[0].markPoint.data[0].yAxis = this.testresult
        this.option.series[0].markPoint.data[0].x = '90%'
        // console.log( this.option.series[0].markPoint.data[0].yAxis  )
        // console.log( this.option.series[0].markPoint.data[0].x )

        // 对于已经init Echart 则不需要重新进行 init, 而直接进行重新进行setOption即可！
        // echarts.getInstanceByDom(document.getElementById("echarts1")).setOption(this.option)
        console.log("WWWWWWWWWWWWWWWWWWWWWWWWWWW")
        console.log( this.option )
        this.ecahrts1()
      }
    },
  },

  watch: {
  },
}
</script>

<style scoped>
.xiaofan {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
  padding: 0 10px;
  /* 给gutter留padding */
}

.p-character {
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  color: #303133;
  margin: 12px
}


.el-row {
  margin-bottom: 20px;
}

.a-style {

  color: #303133;
  cursor: pointer;

  text-decoration: underline;

}



h3 {
  font-weight: 400;
  color: #3d1f23;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  color: #303133;
  margin: 12px;
}

h4 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  color: #303133;
  margin: 12px
}



.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>